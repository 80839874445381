@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.add-boot-camp-participants {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: light.$background-secondary-default;

  &__header-wrapper {
    border-bottom: 1px solid light.$ge-divider-default;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    padding: spacing.$m;
  }

  &__link {
    display: flex;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    row-gap: spacing.$s;
  }

  &__members-results {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
    color: light.$on-surface-primary-alternate;
  }

  &__content {
    flex: 1;
  }

  &__members-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__no-participants,
  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__no-participants-icon {
    padding: spacing.$l;
    border-radius: 100%;
    margin-bottom: spacing.$l;
    display: flex;
    justify-content: center;
    align-items: center;
    background: light.$signal-surface-information;
    color: light.$on-surface-information;
  }

  &__no-participants-text {
    margin-top: spacing.$xs;
    color: light.$on-background-primary-alternate;
  }

  &__too-many-results {
    padding: spacing.$m;
    background: light.$surface-secondary-default;
    border-radius: corner-radius.$s;

    > * + * {
      margin-top: spacing.$xxs;
    }
  }
}
